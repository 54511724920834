import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";
import { AppContext } from "../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 800,
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "scroll",
};

const Suivie = () => {
  const { userData } = useContext(AppContext);
  const [suivies, setSuivies] = useState([]);
  const [data, setData] = useState({
    budgetAlloue: "",
    dateAllocation: "",
    budgetNonAlloue: "",
    commentaire: "",
  });
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [suivie, setSuivie] = useState({
    id: "",
    intitule: "",
    demandeur: "",
    type: "",
    region: "",
    district: "",
    dossier: "",
    notes: [],
  });
  const [currSuivie, setCurrSuivie] = useState(0);

  useEffect(() => {
    setFetching(true);
    if (userData.id) {
      const url = "/suivies";
      axios
        .get(url)
        .then((res) => {
          const suiv = res.data["hydra:member"];
          setSuivies(suiv);
          setFetching(false);
        })
        .catch((e) => setFetching(false));
    }
  }, [userData]);

  const pad = (num, size) => {
    const s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const handleDetails = (suivie) => {
    setFetching(true);

    const getProject = axios.get(`/projets/${suivie.projet.id}`);

    axios
      .all([getProject])
      .then(
        axios.spread((...res) => {
          const prj = res[0].data;
          setSuivie(prj);
          setCurrSuivie(suivie.id);

          setData({
            budgetAlloue: suivie.budget_alloue,
            dateAllocation: suivie.date_allocation,
            budgetNonAlloue: suivie.budget_non_alloue,
            commentaire: suivie.commentaire,
          });

          setFetching(false);
          setOpen(true);
        })
      )
      .catch((e) => setFetching(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetching(true);
    axios
      .patch(`/suivies/${currSuivie}`, data, {
        headers: {
          "Content-Type": "application/merge-patch+json",
        },
      })
      .then((res) => {
        const newSuivies = suivies.map((item) => {
          if (item.id === currSuivie) {
            const newData = {
              budget_alloue: data.budgetAlloue,
              date_allocation: data.dateAllocation,
              budget_non_alloue: data.budgetNonAlloue,
              commentaire: data.commentaire,
            };
            return { ...item, ...newData };
          }
          return item;
        });
        setSuivies(newSuivies);
        setFetching(false);
        setOpen(false);
      })
      .catch((e) => setFetching(false));
  };

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const columnsPopup = [
    {
      field: "id",
      headerName: "No. du dossier",
      valueGetter: (params) =>
        `PROJET/YPA/PNUD/${pad(params.row.projet.id, 5)}`,
    },
    {
      field: "intitule",
      headerName: "Intitulé du projet",
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
    },
    {
      field: "type",
      headerName: "Type du projet ",
      valueGetter: (params) => params.row.projet.type.value,
    },
    {
      field: "region",
      headerName: "Région",
    },
    {
      field: "district",
      headerName: "District",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "No. du dossier",
      flex: 1,
      valueGetter: (params) =>
        `PROJET/YPA/PNUD/${pad(params.row.projet.id, 5)}`,
    },
    {
      field: "projet.intitule",
      headerName: "Intitulé du projet",
      flex: 1,
      valueGetter: (params) => params.row.projet.intitule,
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
      flex: 1,
      valueGetter: (params) => params.row.projet.demandeur,
    },
    {
      field: "type",
      headerName: "Type du projet ",
      flex: 1,
      valueGetter: (params) => params.row.projet.type.value,
    },
    {
      field: "region",
      headerName: "Région",
      flex: 1,
      valueGetter: (params) => params.row.projet.region,
    },
    {
      field: "district",
      headerName: "District",
      flex: 1,
      valueGetter: (params) => params.row.projet.district,
    },
    {
      field: "budget_alloue",
      headerName: "Budget alloué en Ar",
      flex: 1,
    },
    {
      field: "date_allocation",
      headerName: "Date d'allocation",
      flex: 1,
    },
    {
      field: "budget_non_alloue",
      headerName: "Budget non alloué",
      flex: 1,
    },
    {
      field: "commentaire",
      headerName: "Commentaires",
      flex: 2,
    },
    {
      disableExport: true,
      field: "action",
      type: "actions",
      headerName: " ",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => handleDetails(params.row)}
        >
          voir / modifier
        </Button>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: "ProjetsSuivie",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <Grid container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingTop: "8px" }}>
              <Button
                size="small"
                variant="outlined"
                target="_blank"
                href={`${process.env.REACT_APP_BASE_URL}/documents/${suivie.dossier}`}
              >
                Télécharger
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingTop: "8px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            {columnsPopup.map((item) => {
              return (
                <Grid
                  key={item.field}
                  item
                  md={6}
                  xs={12}
                  style={{ paddingTop: "8px" }}
                >
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    {item.headerName}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    {item.field === "id"
                      ? `PROJET/YPA/PNUD/${pad(suivie[item.field], 5)}`
                      : item.field === "type"
                      ? suivie[item.field]?.value
                      : suivie[item.field]}
                  </Typography>
                </Grid>
              );
            })}
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ width: "100%" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="budgetAlloue"
                    label="Budget Alloué en Ariary"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    size="small"
                    value={data.budgetAlloue}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="dateAllocation"
                    label="Date d'Allocation"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    size="small"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={data.dateAllocation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="budgetNonAlloue"
                    label="Budget Non Alloué"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    size="small"
                    value={data.budgetNonAlloue}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={3}
                    name="commentaire"
                    label="Commentaire"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    size="small"
                    value={data.commentaire}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Enregistrer modifications
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Modal>
      {fetching && <LoadingState />}
      <TitlePage title="Suivie des Projets" />
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={suivies}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </Grid>
  );
};

export default Suivie;
