import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SpeedIcon from "@mui/icons-material/Speed";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import RuleIcon from "@mui/icons-material/Rule";
import SuivieIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

export const pages = [
  {
    id: 1,
    title: "Acceuil",
    icon: <SpeedIcon />,
    path: "/",
  },
];

export const userPage = [
  {
    id: 1,
    title: "Gestion Utilisateurs",
    icon: <SupervisedUserCircleIcon />,
    path: "/users",
  },
  {
    id: 2,
    title: "Mon Profile",
    icon: <AccountCircleIcon />,
    path: "/profile",
  },
];
export const projectPage = [
  {
    id: 1,
    title: "Ajout Projet",
    icon: <AddCircleOutlineIcon />,
    path: "/addproject",
  },
  {
    id: 2,
    title: "Liste Projets",
    icon: <ListAltIcon />,
    path: "/listproject",
  },
];
export const evalPage = [
  {
    id: 1,
    title: "Critères d'évaluations",
    icon: <AssignmentTurnedInIcon />,
    path: "/critereevaluation",
  },
  {
    id: 2,
    title: "Evaluation Projets",
    icon: <RuleIcon />,
    path: "/evaluateproject",
  },
  {
    id: 3,
    title: "Rapport Evaluation",
    icon: <PlaylistAddCheckIcon />,
    path: "/reportproject",
  },
];

export const suiviePage = [
  {
    id: 1,
    title: "Suivie Projets",
    icon: <SuivieIcon />,
    path: "/suivie",
  },
];

export const regions = [
  {
    id: 1,
    value: "Androy",
  },
  {
    id: 2,
    value: "Atsimo Andrefana",
  },
];
export const districts = [
  {
    id: 1,
    value: "Beloha",
    region: "Androy",
  },
  {
    id: 2,
    value: "Bekily",
    region: "Androy",
  },
  {
    id: 3,
    value: "Tsihombe",
    region: "Androy",
  },
  {
    id: 4,
    value: "Ambovombe",
    region: "Androy",
  },
  {
    id: 4,
    value: "Betioky-Sud",
    region: "Atsimo Andrefana",
  },
  {
    id: 5,
    value: "Morombe",
    region: "Atsimo Andrefana",
  },
  {
    id: 6,
    value: "Toliara-II",
    region: "Atsimo Andrefana",
  },
];

export const drawerWidth = 240;
