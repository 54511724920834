import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";
import { AppContext } from "../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 800,
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "scroll",
};
const NOTE_MAX = 20;

const Critere = ({ critere, note, showError }) => {
  const [val, setVal] = useState(note);

  const handleChangeCritere = (event) => {
    showError(false);
    const cVal = event.target.value;
    const intVal = parseInt(cVal, 10);
    const note = !isNaN(intVal) ? intVal : "";

    if (note > NOTE_MAX) showError(true);

    setVal(note);
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography variant="h6" style={{ fontSize: "15px" }}>
          {critere.critere}
        </Typography>
        <Typography style={{ fontSize: "12px" }}>
          {critere.description}
        </Typography>
      </div>
      <div>
        <TextField
          required
          name={`${critere.id}`}
          variant="outlined"
          sx={{ mt: 2 }}
          fullWidth
          size="small"
          placeholder="Note"
          value={val}
          onChange={handleChangeCritere}
        />
      </div>
    </Grid>
  );
};

const EvaluateProject = () => {
  const { userData } = useContext(AppContext);

  const [hasError, setHasError] = useState(false);
  const [critaires, setCritaires] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [currentProject, setCurrentProject] = useState("");
  const [currentNote, setCurrentNote] = useState([]);
  const [currentNoteId, setCurrentNoteId] = useState("");
  const [project, setProject] = useState({
    id: "",
    intitule: "",
    demandeur: "",
    type: "",
    region: "",
    district: "",
    dossier: "",
    noteEntretien: "",
  });

  useEffect(() => {
    setFetching(true);
    if (userData.id) {
      const url = `/projets${
        userData.usertype === "Évaluateur" ? `?region=${userData.region}` : ""
      }`;
      axios
        .get(url)
        .then((res) => {
          const prj = res.data["hydra:member"];
          setProjects(prj);
          setFetching(false);
        })
        .catch((e) => setFetching(false));
    }
  }, [userData]);

  const pad = (num, size) => {
    const s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const isEvaluated = (notes) => {
    if (notes.length === 0) return false;
    const exist = notes.find((note) => userData.id === note.user.id);
    return !!exist;
  };

  const getMyEval = (notes) => {
    const myNote = notes.find((note) => userData.id === note.user.id);

    setCurrentNoteId(myNote.id);

    // 1:1#2:1#4:1#5:1#6:1
    const notesPerGroup = myNote.note.split("#");
    return notesPerGroup.map((item) => {
      const note = item.split(":");
      return { critereId: note[0], value: note[1] };
    });
  };

  const getNote = (critereId) => {
    if (currentNote.length > 0) {
      const noteCurrent = currentNote.find(
        (nt) => critereId === parseInt(nt.critereId, 10)
      );
      return noteCurrent?.value ? noteCurrent?.value : "";
    }
    return "";
  };

  const handleEvaluation = (id) => {
    setFetching(true);
    setCurrentProject(id);

    const getProject = axios.get(`/projets/${id}`);
    const getCriterias = axios.get("/criteres");

    axios
      .all([getProject, getCriterias])
      .then(
        axios.spread((...res) => {
          const prj = res[0].data;
          const criterias = res[1].data["hydra:member"];

          setProject(prj);

          if (!isEvaluated(prj.notes)) {
            setEditing(false);
            setCurrentNote((_) => {
              setCritaires(criterias);
              return [];
            });
          } else {
            setEditing(true);
            setCurrentNote((_) => {
              setCritaires(criterias);
              return getMyEval(prj.notes);
            });
          }

          setFetching(false);
          setOpen(true);
        })
      )
      .catch((e) => setFetching(false));
  };

  const columnsPopup = [
    {
      field: "id",
      headerName: "No. du dossier",
      valueGetter: (params) => `PROJET/YPA/PNUD/${pad(params.row.id, 5)}`,
    },
    {
      field: "intitule",
      headerName: "Intitulé du projet",
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
    },
    {
      field: "type",
      headerName: "Type du projet ",
      valueGetter: (params) => params.row.type.value,
    },
    {
      field: "region",
      headerName: "Région",
    },
    {
      field: "district",
      headerName: "District",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "No. du dossier",
      flex: 1,
      valueGetter: (params) => `PROJET/YPA/PNUD/${pad(params.row.id, 5)}`,
    },
    {
      field: "intitule",
      headerName: "Intitulé du projet",
      flex: 1,
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type du projet ",
      flex: 1,
      valueGetter: (params) => params.row.type.value,
    },
    {
      field: "region",
      headerName: "Région",
      flex: 1,
    },
    {
      field: "district",
      headerName: "District",
      flex: 1,
    },
    {
      field: "dossier",
      headerName: "Télécharger",
      valueFormatter: ({ value }) =>
        `${process.env.REACT_APP_BASE_URL}/documents/${value}`,
      renderCell: ({ value }) => (
        <Button
          variant="outlined"
          target="_blank"
          size="small"
          href={`${process.env.REACT_APP_BASE_URL}/documents/${value}`}
        >
          Télécharger PDF
        </Button>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "evaluated",
      headerName: "Évalué",
      flex: 1,
      valueGetter: (params) => (isEvaluated(params.row.notes) ? "OUI" : "NON"),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Évaluer",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => handleEvaluation(params.row.id)}
        >
          Évaluer
        </Button>
      ),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let notesString = "";

    for (const key of formData.keys()) {
      notesString += `#${key}:${formData.get(key).trim()}`;
    }

    notesString = notesString.substring(1);

    setFetching(true);
    if (editing) {
      const newNoteData = {
        note: notesString,
      };

      axios
        .patch(`/notes/${currentNoteId}`, newNoteData, {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        })
        .then((res) => {
          setFetching(false);
          setOpen(false);
        })
        .catch((e) => setFetching(false));
    } else {
      const noteData = {
        note: notesString,
        user: `/api/users/${userData.id}`,
        project: `/api/projets/${currentProject}`,
      };
      axios
        .post("/notes", noteData)
        .then((res) => {
          const appendedNotes = { ...res.data, user: { id: userData.id } };

          const updatedProjects = projects.map((pro) =>
            pro.id === currentProject
              ? { ...pro, notes: [...pro.notes, appendedNotes] }
              : pro
          );
          setProjects(updatedProjects);

          setOpen(false);
          setFetching(false);
        })
        .catch((e) => setFetching(false));
    }
  };

  return (
    <Grid container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingTop: "8px" }}>
              <Button
                size="small"
                variant="outlined"
                target="_blank"
                href={`${process.env.REACT_APP_BASE_URL}/documents/${project.dossier}`}
              >
                Télécharger
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingTop: "8px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            {columnsPopup.map((item) => {
              return (
                <Grid
                  key={item.field}
                  item
                  md={6}
                  xs={12}
                  style={{ paddingTop: "8px" }}
                >
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    {item.headerName}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    {item.field === "id"
                      ? `PROJET/YPA/PNUD/${pad(project[item.field], 5)}`
                      : item.field === "type"
                      ? project[item.field]?.value
                      : project[item.field]}
                  </Typography>
                </Grid>
              );
            })}
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Grid item xs={12}>
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                style={{ width: "100%" }}
              >
                <Grid container spacing={3}>
                  {critaires.map((item) => (
                    <Critere
                      showError={setHasError}
                      critere={item}
                      key={item.id}
                      note={getNote(item.id)}
                    />
                  ))}
                  {hasError && (
                    <Grid item xs={12}>
                      <Typography textAlign="center" color="error">
                        La note maximum est {NOTE_MAX}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={hasError}
                    >
                      Évaluer Projet
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {fetching && <LoadingState />}
      <TitlePage title="Evaluation Des Projets" />
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={projects}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          autoHeight={true}
        />
      </div>
    </Grid>
  );
};

export default EvaluateProject;
