import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  Snackbar,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

import TitlePage from "../Components/TitlePage";
import { regions, districts as dists } from "../Helpers/constants";
import LoadingButton from "../Components/LoadingButton";
import LoadingState from "../Components/LoadingState";

const ErrorComponent = styled("p")`
  color: #dd0c0c;
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
`;

const Utilisateurs = () => {
  const [districts, setDistricts] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setData] = useState({
    nom: "",
    prenom: "",
    region: "",
    district: "",
    username: "",
    usertype: "",
    password: "",
    repassword: "",
  });

  useEffect(() => {
    setFetching(true);
    const getUserTypes = axios.get("/user_types");
    const getUsers = axios.get("/users");

    axios
      .all([getUserTypes, getUsers])
      .then(
        axios.spread((...res) => {
          let allUserTypes = res[0].data["hydra:member"];
          let allUsers = res[1].data["hydra:member"];

          setUsers(allUsers);
          setUserTypes(allUserTypes);
          setFetching(false);
        })
      )
      .catch((e) => setFetching(false));

    const dd = dists.filter((item) => item.region === "Androy");
    setDistricts(dd);
  }, []);

  const pad = (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const handleDelete = (id) => {
    setFetching(true);
    axios
      .delete(`/users/${id}`)
      .then((res) => {
        const newUsers = users.filter((user) => user.id !== id);
        setUsers(newUsers);

        setFetching(false);
      })
      .catch((e) => setFetching(false));
  };

  const columns = [
    {
      field: "id",
      headerName: "No. utilisateur",
      flex: 1,
      valueGetter: (params) => `YPA/PNUD/${pad(params.row.id, 5)}`,
    },
    {
      field: "nom",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "prenom",
      headerName: "Prénom",
      flex: 1,
    },
    {
      field: "region",
      headerName: "Région",
      flex: 1,
    },
    {
      field: "district",
      headerName: "District",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Nom d'utilisateur",
      flex: 1,
    },
    {
      field: "usertype",
      headerName: "Type d'utilisateur",
      flex: 1,
      valueGetter: (params) => params.row.usertype.value,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Supprimer",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "region") {
      const dd = dists.filter((item) => item.region === e.target.value);
      setDistricts(dd);
    }
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    setHasError(false);

    if (data.password !== data.repassword) {
      setErrorMessage("Les nouveaux mots de passes doivent être identiques.");
      setHasError(true);
      setloading(false);
    }

    if (!data.password)
      setData((prev) => ({
        ...prev,
        password: "YPA/PNUD/2022",
        repassword: "YPA/PNUD/2022",
      }));

    axios
      .post("/users", { ...data, usertype: `/api/user_types/${data.usertype}` })
      .then((res) => {
        setUsers((prev) => [
          ...prev,
          {
            ...data,
            id: res.data.id,
            usertype: { value: res.data.usertype.value },
          },
        ]);
        setOpen(true);

        setData({
          nom: "",
          prenom: "",
          region: "",
          district: "",
          username: "",
          usertype: "",
          password: "",
          repassword: "",
        });
        setloading(false);
      })
      .catch((e) => {
        setErrorMessage("Une erreur est survenue");
        setHasError(true);
        setloading(false);
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: "Utilisateurs",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Grid container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="Utilisateur ajouté!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {fetching && <LoadingState />}
      <TitlePage title="Ajout Utilisateur" />
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              required
              name="nom"
              label="Nom"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              value={data.nom}
              size="small"
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              name="prenom"
              label="Prénom"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              size="small"
              value={data.prenom}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth sx={{ mt: 2 }} size="small">
              <InputLabel id="region-label">Région</InputLabel>
              <Select
                labelId="region-label"
                id="region"
                value={data.region}
                label="Région"
                name="region"
                onChange={handleChange}
                required
              >
                {regions.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth sx={{ mt: 2 }} size="small">
              <InputLabel id="district-label">District</InputLabel>
              <Select
                labelId="district-label"
                id="district"
                value={data.district}
                label="District"
                name="district"
                onChange={handleChange}
                required
              >
                {districts.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="username"
              label="Nom d'utilisateur"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              size="small"
              value={data.username}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth sx={{ mt: 2 }} size="small">
              <InputLabel id="usertype-label">Type d'utilisateur</InputLabel>
              <Select
                labelId="usertype-label"
                id="usertype"
                value={data.usertype}
                label="Type d'utilisateur"
                name="usertype"
                onChange={handleChange}
                required
              >
                {userTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="password"
              label="Mot de passe"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              size="small"
              value={data.password}
              onChange={handleChange}
              type="password"
              helperText="Si vide, le mot de passe par defaut seras: YPA/PNUD/2022"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              name="repassword"
              label="Retapez Mot de passe"
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              size="small"
              value={data.repassword}
              onChange={handleChange}
              type="password"
              helperText="Si vide, le mot de passe par defaut seras: YPA/PNUD/2022"
            />
          </Grid>
          {hasError && (
            <Grid item xs={12}>
              <ErrorComponent>{errorMessage}</ErrorComponent>
            </Grid>
          )}
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              title="Ajouter Utilisateur"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ width: "100%", marginTop: "15px" }} />
      <Grid container mt={5}>
        <TitlePage title="Liste des utilisateurs" />
        <div style={{ height: 400, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={users}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                autoHeight={true}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Utilisateurs;
