import axios from "axios";
import jwtDecode from "jwt-decode";

const checkLogin = () => {
  const token = window.localStorage.getItem("authToken");
  if (token) {
    const { exp } = jwtDecode(token);
    if (exp * 1000 > new Date().getTime()) {
      setAxiosHeader(token);
      return true;
    }
  }
  return false;
};

const login = (token) => {
  window.localStorage.setItem("authToken", token);
  const userData = jwtDecode(token);
  window.localStorage.setItem("userData", JSON.stringify(userData));
  setAxiosHeader(token);
};

const logout = () => {
  delete axios.defaults.headers["Authorization"];
  window.localStorage.removeItem("authToken");
};

const getUserData = () => {
  const userData = window.localStorage.getItem("userData");
  return JSON.parse(userData);
};

const updateUserData = (userData) => {
  window.localStorage.setItem("userData", JSON.stringify(userData));
};

const setAxiosHeader = (token) => {
  axios.defaults.headers["Authorization"] = "Bearer " + token;
};

const authAPI = { checkLogin, logout, login, getUserData, updateUserData };

export default authAPI;
