import { Typography, Grid } from "@mui/material";

const TitlePage = ({ title }) => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h5"
        mb={4}
        style={{
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default TitlePage;
