import {
  Grid,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeCard = ({ pages, title }) => {
  const navigate = useNavigate();

  return (
    <Grid item md={4} xs={12}>
      <Card>
        <CardContent>
          <Typography
            sx={{
              fontSize: 14,
            }}
            color="text.secondary"
            gutterBottom
          >
            {title}
          </Typography>
          <List>
            {pages.map((item) => (
              <ListItem
                key={item.id}
                disablePadding
                onClick={() => navigate(item.path)}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default HomeCard;
