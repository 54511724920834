import {useContext, useState, useEffect} from "react";
import {Grid, Typography} from "@mui/material";

import {userPage, projectPage, evalPage, suiviePage} from "../Helpers/constants";
import HomeCard from "../Components/HomeCard";
import {AppContext} from "../App";

const Home = () => {
    const {userData} = useContext(AppContext);
    const [userPagesRender, setuserPagesRender] = useState(userPage);
    const [evalPageRender, setEvalPageRender] = useState(evalPage);

    useEffect(() => {
        if (userData.usertype !== "Administrateur" && userData.id) {
            setuserPagesRender([userPage[1]]);
            setEvalPageRender(
                evalPage.filter((item) => item.path !== "/critereevaluation")
            );
        }
    }, [userData]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4" mb={4}>
                    Bonjour {userData.nom} {userData.prenom}
                </Typography>
                <Grid container spacing={2}>
                    {/* Only show project pages to Admin and YPA */}
                    {(userData.usertype === "YPA" ||
                        userData.usertype === "Administrateur") && (
                        <HomeCard pages={projectPage} title="Gestion Projets"/>
                    )}
                    {/* Only show evaluation pages to Admin and Evaluateurs */}
                    {(userData.usertype === "Évaluateur" ||
                        userData.usertype === "Administrateur") && (
                        <HomeCard pages={evalPageRender} title="Evaluation Projets"/>
                    )}
                    {/* Only show suivie pages to Admin and YPA */}
                    {(userData.usertype === "YPA" ||
                        userData.usertype === "Administrateur") && (
                        <HomeCard pages={suiviePage} title="Suivie Projets"/>
                    )}
                    <HomeCard pages={userPagesRender} title="Gestion Utilisateurs"/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Home;
