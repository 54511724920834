import { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";

const pad = (num, size) => {
  var s = "000000000" + num;
  return s.substr(s.length - size);
};

const columns = [
  {
    field: "id",
    headerName: "No. du dossier",
    flex: 1,
    valueGetter: (params) => `PROJET/YPA/PNUD/${pad(params.row.id, 5)}`,
  },
  {
    field: "intitule",
    headerName: "Intitulé du projet",
    flex: 1,
  },
  {
    field: "demandeur",
    headerName: "Demandeur / Organisation",
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type du projet ",
    flex: 1,
    valueGetter: (params) => params.row.type.value,
  },
  {
    field: "region",
    headerName: "Région",
    flex: 1,
  },
  {
    field: "district",
    headerName: "District",
    flex: 1,
  },
  {
    field: "dossier",
    headerName: "Télécharger",
    valueFormatter: ({ value }) =>
      `${process.env.REACT_APP_BASE_URL}/documents/${value}`,
    renderCell: ({ value }) => (
      <Button
        variant="outlined"
        target="_blank"
        size="small"
        href={`${process.env.REACT_APP_BASE_URL}/documents/${value}`}
      >
        Télécharger PDF
      </Button>
    ),
    flex: 1,
    sortable: false,
  },
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: "ListeProjets",
          delimiter: ";",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const ListProject = () => {
  const [projects, setProjects] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    axios
      .get("/projets")
      .then((res) => {
        const prj = res.data["hydra:member"];
        setProjects(prj);
        setFetching(false);
      })
      .catch((e) => setFetching(false));
  }, []);

  return (
    <Grid container>
      {fetching && <LoadingState />}
      <TitlePage title="Liste Des Projets" />
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={projects}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          autoHeight={true}
        />
      </div>
    </Grid>
  );
};

export default ListProject;
