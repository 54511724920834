import React, { useState, createContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { Routes, Route } from "react-router-dom";

import AppBar from "./Components/Header/AppBar";
import SideBar from "./Components/Header/SideBar";
import DrawerHeader from "./Components/Header/DrawerHeader";

import Home from "./Screen/Home";
import SignIn from "./Screen/SignIn";
import Profile from "./Screen/Profile";
import NotFound from "./Screen/NotFound";
import AddProject from "./Screen/AddProject";
import Utilisateurs from "./Screen/Utilisateurs";
import ListProject from "./Screen/ListProject";
import EvaluateProject from "./Screen/EvaluateProject";
import ReportEvaluation from "./Screen/ReportEvaluation";
import CritereEvaluaion from "./Screen/CritereEvaluaion";
import Suivie from "./Screen/Suivie";

import authAPI from "./Helpers/auth";

export const AppContext = createContext();

export default function MiniDrawer() {
  const [open, setOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const login = authAPI.checkLogin();
    setIsLogged(login);
    if (login) {
      setUserData(authAPI.getUserData());
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ isLogged, setIsLogged, userData, setUserData }}
    >
      <Box sx={{ display: "flex" }}>
        {isLogged ? (
          <>
            <AppBar open={open} setOpen={setOpen} />
            <SideBar open={open} setOpen={setOpen} />

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <DrawerHeader />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="users" element={<Utilisateurs />} />
                <Route path="addproject" element={<AddProject />} />
                <Route path="listproject" element={<ListProject />} />
                <Route
                  path="critereevaluation"
                  element={<CritereEvaluaion />}
                />
                <Route path="evaluateproject" element={<EvaluateProject />} />
                <Route path="suivie" element={<Suivie />} />
                <Route path="reportproject" element={<ReportEvaluation />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Box>
          </>
        ) : (
          <SignIn />
        )}
      </Box>
    </AppContext.Provider>
  );
}
