import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";
import { regions, districts as dists } from "../Helpers/constants";

const ListContainer = styled(List)`
  background: #ececec;
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
`;

const ErrorComponent = styled("p")`
  color: #dd0c0c;
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
`;

const AddProject = () => {
  const [data, setData] = useState({
    intitule: "",
    demandeur: "",
    type: "",
    region: "",
    district: "",
    dossier: "",
  });

  const [districts, setDistricts] = useState([]);
  const [projectTypeName, setProjectTypeName] = useState("");
  const [modeEdit, setModeEdit] = useState(false);
  const [curentEdit, setCurentEdit] = useState({});
  const [fetching, setFetching] = useState(false);
  const [file, setFile] = useState("");
  const [typeProjets, setTypeProjets] = useState([]);
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setFetching(true);
    axios
      .get("/types_de_projets")
      .then((res) => {
        const tpProj = res.data["hydra:member"];
        setTypeProjets(tpProj);
        setFetching(false);
      })
      .catch((e) => setFetching(false));

    const dd = dists.filter((item) => item.region === "Androy");
    setDistricts(dd);
  }, []);

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "region") {
      const dd = dists.filter((item) => item.region === e.target.value);
      setDistricts(dd);
    }
  };
  const handleChangeProjectList = (e) => {
    setProjectTypeName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetching(true);
    setHasError(false);

    if (!data.dossier) {
      setErrorMessage("Veuillez ajouter un fichier!");
      setHasError(true);
      setFetching(false);
      return;
    }

    axios
      .post("/projets", {
        ...data,
        type: `/api/types_de_projets/${data.type}`,
        evaluated: "0",
      })
      .then((res) => {
        setFetching(false);
        setMessageSnack("Projet Ajouté!");
        setOpen(true);
        setData({
          intitule: "",
          demandeur: "",
          type: "",
          region: "",
          district: "",
          dossier: "",
        });
        setFile("");
      })
      .catch((e) => setFetching(false));
  };

  const uploadFile = ({ target }) => {
    setFetching(true);
    setHasError(false);
    const formData = new FormData();
    const doc = target.files[0];

    formData.append("document", doc);
    formData.append("fileName", `Dossier_Projet_${new Date().getTime()}`);
    formData.append("extension", "pdf");

    axios
      .post("/upload_document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const docFile = res.data["hydra:member"][0];
        setFile(doc.name);
        setData({ ...data, dossier: docFile });
        setFetching(false);
        setMessageSnack("Fichier téléchargé");
        setOpen(true);
      })
      .catch((e) => setFetching(false));
  };

  const handleSubmitProjectList = (e) => {
    e.preventDefault();
    if (projectTypeName) {
      setFetching(true);
      if (modeEdit) {
        axios
          .patch(
            `/types_de_projets/${curentEdit.id}`,
            {
              value: projectTypeName,
            },
            {
              headers: {
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then((res) => {
            const newProjectList = typeProjets.map((item) => {
              if (item.id === curentEdit.id) {
                return { id: item.id, value: projectTypeName };
              }
              return item;
            });
            setTypeProjets(newProjectList);
            setProjectTypeName("");
            setModeEdit(false);
            setFetching(false);
            setMessageSnack("Type de projet Mise à jour");
            setOpen(true);
          })
          .catch((e) => setFetching(false));
      } else {
        axios
          .post("/types_de_projets", {
            value: projectTypeName,
          })
          .then((res) => {
            setTypeProjets((prev) => [
              ...prev,
              { id: res.data.id, value: projectTypeName },
            ]);
            setProjectTypeName("");
            setFetching(false);
            setMessageSnack("Type de projet ajouté");
            setOpen(true);
          })
          .catch((e) => setFetching(false));
      }
    }
  };

  const handleEdit = (projectType) => {
    setCurentEdit(projectType);
    setProjectTypeName(projectType.value);
    setModeEdit(true);
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Grid container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message={messageSnack}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {fetching && <LoadingState />}
      <Grid item md={9} xs={12} p={2}>
        <TitlePage title="Ajout De Projet" />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                name="intitule"
                label="Intitulé du projet"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.intitule}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                name="demandeur"
                label="Demandeur / Organisation"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.demandeur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="type-label">Type du projet</InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  value={data.type}
                  label="Type du projet"
                  name="type"
                  onChange={handleChange}
                  required
                >
                  {typeProjets.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="region-label">Région</InputLabel>
                <Select
                  labelId="region-label"
                  id="region"
                  value={data.region}
                  label="Région"
                  name="region"
                  onChange={handleChange}
                  required
                >
                  {regions.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }} size="small">
                <InputLabel id="district-label">District</InputLabel>
                <Select
                  labelId="district-label"
                  id="district"
                  value={data.district}
                  label="District"
                  name="district"
                  onChange={handleChange}
                  required
                >
                  {districts.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} style={{ display: "flex" }}>
              <FormControl
                fullWidth
                sx={{ mt: 2 }}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <label htmlFor="upload-project">
                  <input
                    style={{ display: "none" }}
                    id="upload-project"
                    name="upload-project"
                    type="file"
                    accept="application/pdf"
                    onChange={uploadFile}
                  />

                  <Button color="primary" variant="contained" component="span">
                    {file ? "Modifier Dossier PDF" : "Ajouter Dossier PDF *"}
                  </Button>
                  {file && <span style={{ marginLeft: "10px" }}>{file}</span>}
                </label>
              </FormControl>
            </Grid>
            {hasError && (
              <Grid item xs={12}>
                <ErrorComponent>{errorMessage}</ErrorComponent>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Enregistrer Projet
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={3} xs={12} p={2}>
        <TitlePage title="Types De Projet" />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmitProjectList}
          style={{ width: "100%", display: "flex", marginTop: "10px" }}
        >
          <TextField
            required
            name="titre"
            label="Ajouter type de projet"
            variant="outlined"
            fullWidth
            value={projectTypeName}
            onChange={handleChangeProjectList}
            size="small"
          />
          <Button
            style={{ marginLeft: "10px" }}
            variant="contained"
            type="submit"
          >
            {modeEdit ? <EditIcon /> : <AddIcon />}
          </Button>
        </Box>

        <ListContainer>
          {typeProjets.map((item) => (
            <div key={item.id}>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="modifier"
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={item.value} />
              </ListItem>
              <Divider />
            </div>
          ))}
        </ListContainer>
      </Grid>
    </Grid>
  );
};

export default AddProject;
