import { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Snackbar,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

import TitlePage from "../Components/TitlePage";
import LoadingButton from "../Components/LoadingButton";

import { AppContext } from "../App";
import authAPI from "../Helpers/auth";

const ErrorComponent = styled("p")`
  color: #dd0c0c;
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
`;

const Profile = () => {
  const [open, setOpen] = useState(false);
  const { userData, setUserData } = useContext(AppContext);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingPwd, setLoadingPwd] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({
    nom: "",
    prenom: "",
    region: "",
    district: "",
    username: "",
    usertype: "",
  });
  const [dataPassword, setDataPassword] = useState({
    old: "",
    new: "",
    renew: "",
  });

  useEffect(() => {
    if (userData.nom) {
      setData(userData);
    }
  }, [userData]);

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePwd = (e) => {
    setDataPassword((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const { nom, prenom } = data;
    setLoadingInfo(true);
    axios
      .patch(
        `/users/${userData.id}`,
        { nom, prenom },
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        }
      )
      .then(() => {
        authAPI.updateUserData(data);
        setUserData(data);
        setLoadingInfo(false);
        setOpen(true);
      })
      .catch(() => setLoadingInfo(false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingPwd(true);
    setHasError(false);

    if (dataPassword.new !== dataPassword.renew) {
      setErrorMessage("Les nouveaux mots de passes doivent être identiques.");
      setHasError(true);
      setLoadingPwd(false);
      return;
    }

    if (dataPassword.new === dataPassword.old) {
      setErrorMessage("Veuillez ne pas utiliser votre mot de passe actuel.");
      setHasError(true);
      setLoadingPwd(false);
      return;
    }

    setLoadingPwd(true);
    const formData = new FormData();

    formData.append("currPwd", dataPassword.old);
    formData.append("newPwd", dataPassword.new);
    formData.append("userId", userData.id);

    axios
      .post("/update_password", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        const isCorrect = res.data["hydra:member"][0];
        if (!isCorrect) {
          setErrorMessage("Mauvaise mot de passe. Veuillez re-essayer!");
          setHasError(true);
          setLoadingPwd(false);
          return;
        }
        setOpen(true);
        setLoadingPwd(false);
        setDataPassword({
          old: "",
          new: "",
          renew: "",
        });
      })
      .catch((e) => setLoadingPwd(false));
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Grid container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="Information mise à jour"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Grid item md={6} xs={12} p={2}>
        <TitlePage title="Profile" />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleUpdateProfile}
          style={{ width: "100%" }}
        >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                name="nom"
                label="Nom"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.nom}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                name="prenom"
                label="Prénom"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.prenom}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled
                name="region"
                label="Région"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.region}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="district"
                label="District"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                disabled
                value={data.district}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled
                name="username"
                label="Nom d'utilisateur"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.username}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="usertype"
                label="Type d'utilisateur"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                disabled
                value={data.usertype}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loadingInfo}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                title="Mettre à jour information"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} p={2}>
        <TitlePage title="Mot de passe" />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                type="password"
                required
                name="old"
                label="Ancienne Mot de passe"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={dataPassword.old}
                onChange={handleChangePwd}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="new"
                type="password"
                label="Nouvelle Mot de passe"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={dataPassword.new}
                onChange={handleChangePwd}
                required
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="renew"
                type="password"
                label="Retapez Mot de passe"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={dataPassword.renew}
                onChange={handleChangePwd}
                required
              />
            </Grid>
            <Grid item xs={12}>
              {hasError && <ErrorComponent>{errorMessage}</ErrorComponent>}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loadingPwd}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                title="Mettre à jour mot de passe"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profile;
