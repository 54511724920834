import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";
import { AppContext } from "../App";
import { TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 800,
  width: "100%",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "scroll",
};

const CCOntainer = styled("div")`
  align-self: center;
  margin-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 10px;
`;

const extractNote = (note) => {
  const notesPerGroup = note.split("#");
  return notesPerGroup.map((item) => {
    const note = item.split(":");
    return { critereId: note[0], value: note[1] };
  });
};

const Critere = ({ critere, index }) => {
  return (
    <Grid
      item
      xs={12}
      style={{
        paddingTop: "2px",
        paddingBottom: "2px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
      }}
    >
      <CCOntainer>Critère {index + 1}</CCOntainer>
      <div style={{ flex: 1 }}>
        <Typography variant="h6" style={{ fontSize: "13px" }}>
          {critere.critere}
        </Typography>
        <Typography style={{ fontSize: "12px" }}>
          {critere.description}
        </Typography>
      </div>
    </Grid>
  );
};

const EvalComponent = ({ note }) => {
  const [sum, setSum] = useState(0);
  const [avg, setAvg] = useState(0);
  const [noteObj, setNoteObj] = useState([]);

  useEffect(() => {
    const notes = extractNote(note.note);
    setNoteObj(notes);
    const sm = notes.reduce(
      (cumul, item) => cumul + parseInt(item.value, 10),
      0
    );

    setSum(sm);
    const av = sm / notes.length;
    setAvg(parseFloat(av.toFixed(2)));
  }, [note]);

  const getNotes = () => {
    return (
      <>
        {noteObj.map((nt, i) => (
          <div key={nt.critereId}>
            Critère {i + 1}: {nt.value}
          </div>
        ))}
      </>
    );
  };

  return (
    <tr
      style={{
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <td style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px" }}>
        {note.user.nom.toUpperCase().substr(0, 1)}. {note.user.prenom}
      </td>
      <td style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "5px" }}>
        {getNotes()}
      </td>
      <td
        style={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          textAlign: "center",
          padding: "5px",
        }}
      >
        {sum}
      </td>
      <td
        style={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          textAlign: "center",
          padding: "5px",
        }}
      >
        {avg}
      </td>
    </tr>
  );
};

const ReportEvaluation = () => {
  const { userData } = useContext(AppContext);
  const [critaires, setCritaires] = useState([]);
  const [projects, setProjects] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState({
    id: "",
    intitule: "",
    demandeur: "",
    type: "",
    region: "",
    district: "",
    dossier: "",
    notes: [],
  });

  useEffect(() => {
    setFetching(true);
    if (userData.id) {
      const url = `/projets${
        userData.usertype === "Évaluateur" ? `?region=${userData.region}` : ""
      }`;
      axios
        .get(url)
        .then((res) => {
          const prj = res.data["hydra:member"];
          setProjects(prj);
          setFetching(false);
        })
        .catch((e) => setFetching(false));
    }
  }, [userData]);

  const pad = (num, size) => {
    const s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const calculateFinalNotes = (notes) => {
    if (notes.length) {
      const allNotes = notes.map((nt) => {
        const nts = extractNote(nt.note);
        const sum = nts.reduce(
          (cumul, item) => cumul + parseInt(item.value, 10),
          0
        );

        return sum / nts.length;
      });
      const total = allNotes.reduce((cumul, item) => cumul + item, 0);
      const avgg = total / notes.length;
      return parseFloat(avgg.toFixed(2));
    }
    return 0;
  };

  const handleDetails = (id) => {
    setFetching(true);

    const getProject = axios.get(`/projets/${id}`);
    const getCriterias = axios.get("/criteres");

    axios
      .all([getProject, getCriterias])
      .then(
        axios.spread((...res) => {
          const prj = res[0].data;
          const criterias = res[1].data["hydra:member"];
          setProject(prj);
          setCritaires(criterias);

          setFetching(false);
          setOpen(true);
        })
      )
      .catch((e) => setFetching(false));
  };

  const handleSuivie = (id) => {
    setFetching(true);
    const data = {
      projet: `/api/projets/${id}`,
      budgetAlloue: "",
      dateAllocation: "",
      budgetNonAlloue: "",
      commentaire: "",
    };

    axios
      .post("/suivies", data)
      .then((res) => {
        setProjects((oldProjs) => {
          const newProjs = oldProjs.map((pro) => {
            if (pro.id === id) return { ...pro, suivie: "yes" };
            return pro;
          });
          return newProjs;
        });
        setFetching(false);
      })
      .catch((e) => setFetching(false));
  };

  const renderNoteEntretien = (note) => (note ? note : "0");

  const calculateNoteMoyenne = (noteEval, noteEntr) => {
    let evl = calculateFinalNotes(noteEval);
    const entr = Number.isNaN(parseFloat(noteEntr)) ? 0 : parseFloat(noteEntr);
    return ((evl + entr) / 2).toFixed(2);
  };

  const columnsPopup = [
    {
      field: "id",
      headerName: "No. du dossier",
      valueGetter: (params) => `PROJET/YPA/PNUD/${pad(params.row.id, 5)}`,
    },
    {
      field: "intitule",
      headerName: "Intitulé du projet",
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
    },
    {
      field: "type",
      headerName: "Type du projet ",
      valueGetter: (params) => params.row.type.value,
    },
    {
      field: "region",
      headerName: "Région",
    },
    {
      field: "district",
      headerName: "District",
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "No. du dossier",
      flex: 1,
      valueGetter: (params) => `PROJET/YPA/PNUD/${pad(params.row.id, 5)}`,
    },
    {
      field: "intitule",
      headerName: "Intitulé du projet",
      flex: 1,
    },
    {
      field: "demandeur",
      headerName: "Demandeur / Organisation",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type du projet ",
      flex: 1,
      valueGetter: (params) => params.row.type.value,
    },
    {
      field: "region",
      headerName: "Région",
      flex: 1,
    },
    {
      field: "district",
      headerName: "District",
      flex: 1,
    },
    {
      field: "noteEntretien",
      headerName: "Entretien",
      flex: 1,
      valueGetter: (params) => renderNoteEntretien(params.row.noteEntretien),
    },
    {
      field: "evaluated",
      headerName: "Évaluation",
      flex: 1,
      valueGetter: (params) => calculateFinalNotes(params.row.notes),
    },
    {
      field: "typeId",
      headerName: "Moyenne",
      flex: 1,
      valueGetter: (params) =>
        calculateNoteMoyenne(params.row.notes, params.row.noteEntretien),
    },
    {
      disableExport: true,
      field: "dossier",
      headerName: "Télécharger",
      valueFormatter: ({ value }) =>
        `${process.env.REACT_APP_BASE_URL}/documents/${value}`,
      renderCell: ({ value }) => (
        <Button
          variant="outlined"
          target="_blank"
          size="small"
          href={`${process.env.REACT_APP_BASE_URL}/documents/${value}`}
        >
          Télécharger PDF
        </Button>
      ),
      flex: 1,
      sortable: false,
    },
    {
      disableExport: true,
      field: "action",
      type: "actions",
      headerName: "Détails",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="success"
          size="small"
          onClick={() => handleDetails(params.row.id)}
        >
          voir détails
        </Button>
      ),
    },
    {
      disableExport: true,
      field: "action2",
      type: "actions",
      headerName: "Selection",
      flex: 1,
      renderCell: (params) => (
        <Button
          disabled={params.row?.suivie ? true : false}
          variant="contained"
          color="warning"
          size="small"
          onClick={() => handleSuivie(params.row.id)}
        >
          Sélectionner
        </Button>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: "RapportEvaluation",
            delimiter: ";",
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  };

  const handleEntretienChange = (e) => {
    const { value } = e.target;
    setProject((oldProj) => ({ ...oldProj, noteEntretien: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const noteEntretien = project?.noteEntretien
      ? project?.noteEntretien + ""
      : "0";

    setIsLoading(true);
    axios
      .patch(
        `/projets/${project.id}`,
        { noteEntretien },
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
          },
        }
      )
      .then((res) => {
        setProjects((oldProjs) => {
          const newProjs = oldProjs.map((pro) => {
            if (pro.id === project.id)
              return { ...pro, noteEntretien: noteEntretien };
            return pro;
          });
          return newProjs;
        });
        setIsLoading(false);
        setOpen(false);
      })
      .catch((e) => setFetching(false));
  };

  return (
    <Grid container>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={6} style={{ paddingTop: "8px" }}>
              <Button
                size="small"
                variant="outlined"
                target="_blank"
                href={`${process.env.REACT_APP_BASE_URL}/documents/${project.dossier}`}
              >
                Télécharger
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingTop: "8px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            {columnsPopup.map((item) => {
              return (
                <Grid
                  key={item.field}
                  item
                  md={6}
                  xs={12}
                  style={{ paddingTop: "8px" }}
                >
                  <Typography variant="h6" style={{ fontSize: "16px" }}>
                    {item.headerName}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    {item.field === "id"
                      ? `PROJET/YPA/PNUD/${pad(project[item.field], 5)}`
                      : item.field === "type"
                      ? project[item.field]?.value
                      : project[item.field]}
                  </Typography>
                </Grid>
              );
            })}
            <Divider style={{ width: "100%", margin: "10px 0" }} />
            <Grid item xs={12}>
              <Box style={{ width: "100%" }}>
                <Grid container spacing={3}>
                  {critaires.map((item, i) => (
                    <Critere index={i} critere={item} key={item.id} />
                  ))}
                </Grid>
              </Box>
            </Grid>
            {project.notes.length > 0 ? (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Évaluateur</th>
                    <th>Évaluations</th>
                    <th>Somme</th>
                    <th>Moyenne</th>
                  </tr>
                </thead>
                <tbody>
                  {project.notes.map((nt) => (
                    <EvalComponent key={nt.id} note={nt} />
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <TextField
                          required
                          name="noteEntretien"
                          variant="outlined"
                          fullWidth
                          size="small"
                          label="Note Entretien"
                          placeholder="Note Entretien"
                          value={project?.noteEntretien || "0"}
                          onChange={handleEntretienChange}
                          sx={{ mr: 1 }}
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={isLoading}
                        >
                          Changer
                        </Button>
                      </Box>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <h4 style={{ margin: "5px 0" }}>
                        Note Évaluation: {calculateFinalNotes(project.notes)}
                      </h4>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <h4 style={{ margin: "5px 0" }}>
                        Moyenne:{" "}
                        {calculateNoteMoyenne(
                          project.notes,
                          project.noteEntretien
                        )}
                      </h4>
                    </td>
                  </tr>
                </tfoot>
              </table>
            ) : (
              <Grid item xs={12}>
                <h3 style={{ textAlign: "center" }}>Pas d'évaluation</h3>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
      {fetching && <LoadingState />}
      <TitlePage title="Rapport Des Evaluations" />
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={projects}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          disableSelectionOnClick
          autoHeight={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </Grid>
  );
};

export default ReportEvaluation;
