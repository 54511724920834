import { Button, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

const LoadingButton = ({ loading, title, ...props }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Button {...props} disabled={loading}>
        {title}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};

export default LoadingButton;
