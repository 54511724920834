import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      spacing={5}
      sx={{ mt: 5 }}
      direction="row"
      justifyContent="center"
    >
      <Grid
        item
        sm={10}
        xs={12}
        direction="column"
        container
        display="flex"
        alignItems="center"
      >
        <Typography variant="h3">Oops, page non trouvé</Typography>
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => navigate("/")}
        >
          Retour à la page d'acceuil
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;
