import { useContext, useState } from "react";
import {
  Box,
  styled,
  Typography,
  Container,
  Avatar,
  TextField,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";

import { AppContext } from "../App";
import authAPI from "../Helpers/auth";
import LoadingButton from "../Components/LoadingButton";

const ErrorComponent = styled("p")`
  color: #dd0c0c;
  text-align: center;
  font-size: 14px;
  margin: 8px 0;
`;

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { setIsLogged, setUserData } = useContext(AppContext);

  const handleLogin = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      username: formData.get("user"),
      password: formData.get("password"),
    };
    setLoading(true);
    setHasError(false);

    axios
      .post("/login_check", data)
      .then((res) => {
        const token = res.data.token;
        authAPI.login(token);
        setUserData(authAPI.getUserData());
        setIsLogged(true);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="user"
            label="Nom d'utilisateur"
            name="user"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mots de passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {hasError && (
            <ErrorComponent>
              Nom d'utilisateur incorrect ou Mot de passe
            </ErrorComponent>
          )}

          <LoadingButton
            title="Connexion"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          />
        </Box>
      </Box>
    </Container>
  );
}
