import { styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { blue } from "@mui/material/colors";

const Container = styled("div")`
  position: absolute;
  background: #66666621;
  width: 100%;
  height: 100%;
  z-index: 9999;
  border-radius: 10px;
`;

const LoadingState = ({ loading, title, ...props }) => {
  return (
    <Container>
      <CircularProgress
        size={50}
        sx={{
          color: blue[500],
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-25px",
          marginLeft: "-25px",
        }}
      />
    </Container>
  );
};

export default LoadingState;
