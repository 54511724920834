import React, {useContext, useState, useEffect} from "react";
import {styled} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate, useLocation} from "react-router-dom";

import DrawerHeader from "./DrawerHeader";
import {
    drawerWidth,
    pages,
    userPage,
    projectPage,
    evalPage,
    suiviePage
} from "../../Helpers/constants";

import {AppContext} from "../../App";

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const CustomList = styled(ListItem)`
  &.activemenu {
    background-color: lightgray;
  }
`;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const Item = ({pages, divider = true}) => {
    let navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {divider && <Divider/>}
            {pages.map((page) => (
                <CustomList
                    button
                    className={`${location.pathname === page.path ? "activemenu" : ""}`}
                    key={page.id}
                    onClick={() => navigate(page.path)}
                >
                    <ListItemIcon>{page.icon}</ListItemIcon>
                    <ListItemText primary={page.title}/>
                </CustomList>
            ))}
        </>
    );
};

const SideBar = ({open, setOpen}) => {
    const {userData} = useContext(AppContext);

    const [userPagesRender, setuserPagesRender] = useState(userPage);
    const [evalPageRender, setEvalPageRender] = useState(evalPage);

    useEffect(() => {
        if (userData.usertype !== "Administrateur" && userData.id) {
            setuserPagesRender([userPage[1]]);
            setEvalPageRender(
                evalPage.filter((item) => item.path !== "/critereevaluation")
            );
        }
    }, [userData]);

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={() => setOpen(false)}>
                    <ChevronLeftIcon/>
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List>
                <Item divider={false} pages={pages}/>
                {/* Only show project pages to Admin and YPA */}
                {(userData.usertype === "YPA" || userData.usertype === "Administrateur") && (
                    <Item pages={projectPage}/>
                )}
                {/* Only show evaluation pages to Admin and Evaluateurs */}
                {(userData.usertype === "Évaluateur" || userData.usertype === "Administrateur") && (
                    <Item pages={evalPageRender}/>
                )}
                {/* Only show suivie pages to Admin and YPA */}
                {(userData.usertype === "YPA" || userData.usertype === "Administrateur") && (
                    <Item pages={suiviePage}/>
                )}
                {/* Only show user management pages to Admin */}
                <Item pages={userPagesRender}/>
            </List>
        </Drawer>
    );
};

export default SideBar;
