import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  styled,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import TitlePage from "../Components/TitlePage";
import LoadingState from "../Components/LoadingState";

const ListContainer = styled(List)`
  background: #ececec;
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
`;

const CritereEvaluaion = () => {
  const [data, setData] = useState({
    critere: "",
    description: "",
  });
  const [criteres, setCriteres] = useState([]);
  const [modeEdit, setModeEdit] = useState(false);
  const [curentEdit, setCurentEdit] = useState({});
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFetching(true);
    axios
      .get("/criteres")
      .then((res) => {
        const crits = res.data["hydra:member"];
        setCriteres(crits);
        setFetching(false);
      })
      .catch((e) => setFetching(false));
  }, []);

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.critere) {
      setFetching(true);
      if (modeEdit) {
        axios
          .patch(`/criteres/${curentEdit.id}`, data, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((res) => {
            const newCriteres = criteres.map((item) => {
              if (item.id === curentEdit.id) {
                return { id: item.id, ...data };
              }
              return item;
            });
            setCriteres(newCriteres);
            setModeEdit(false);
            setFetching(false);
            setOpen(true);
            setData({
              critere: "",
              description: "",
            });
          })
          .catch((e) => setFetching(false));
      } else {
        axios
          .post("/criteres", { ...data, evaluation: "0", notes: "0" })
          .then((res) => {
            setCriteres((prev) => [...prev, { ...res.data }]);
            setFetching(false);
            setOpen(true);
            setData({
              critere: "",
              description: "",
            });
          })
          .catch((e) => setFetching(false));
      }
    }
  };

  const handleEdit = (criteria) => {
    setCurentEdit(criteria);
    setData({
      critere: criteria.critere,
      description: criteria.description,
    });
    setModeEdit(true);
  };

  const handleDelete = (id) => {
    setFetching(true);
    axios
      .delete(`/criteres/${id}`)
      .then((res) => {
        const newCriteres = criteres.filter((item) => item.id !== id);
        setCriteres(newCriteres);

        setFetching(false);
        setOpen(true);
      })
      .catch((e) => setFetching(false));
  };

  const handleCloseSnackBar = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Grid container>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        message="Critère enregistrée!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {fetching && <LoadingState />}
      <Grid item md={6} xs={12} p={2}>
        <TitlePage title="Critères d'évaluations" />
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                name="critere"
                label="Critère"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.critere}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                multiline
                rows={3}
                name="description"
                label="Description"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                value={data.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {modeEdit ? "Enregistrer modifications" : "Ajouter Critère"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={6} xs={12} p={2}>
        <TitlePage title="Liste des critères" />
        {criteres.length > 0 && (
          <ListContainer>
            {criteres.map((item) => (
              <div key={item.id}>
                <ListItem
                  secondaryAction={
                    <>
                      <IconButton
                        edge="end"
                        aria-label="modifier"
                        onClick={() => handleEdit(item)}
                        style={{ marginRight: 5 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="supprimer"
                        onClick={() => handleDelete(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText
                    primary={item.critere}
                    secondary={item.description}
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </ListContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default CritereEvaluaion;
